import React from "react";

function About() {
  return (
    <div className="bg-gray-800">
      <h1>About Us</h1>
    </div>
  );
}

export default About;
